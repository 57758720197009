<template>
    <div class="border-r-2">

        <tabs @tabChange="tabChange" :selected-index="parseInt(selectedIndex)"
              mode="line" color="" align="center" class="c-tabs">

            <tab title="Bank/FI’s" class="c-scroll thin-scrollbar">

                <custom-vue-table-card class="d-sm-none-lg-block" :fields="BankAdminFields" :url=bankAdminList
                                       :per-page="10" :show-search-box="false"
                                       ref="table">

                    <template slot="bank_logo" slot-scope="props">
                        <div class="logo-container">
                            <img width="100" height="100" v-if="props.rowData.bank_logo" class="img-fluid"
                                 :src="props.rowData.bank_logo"
                                 alt="#BankLogo">
                        </div>
                    </template>

                    <template slot="bank_name" slot-scope="props">
                        <span class="capitalize-string" v-html="props.rowData.bank_name"></span>
                    </template>

                    <template slot="attachment">
                        <router-link to="/" class="text-decoration-none line-bottom-primary">View</router-link>
                    </template>

                    <template slot="actions" slot-scope="props">
                        <div class="btn-group">
                            <btn class="ml-3" text="View Users" color="secondary"
                                 @click="$router.push({path: '/bank-admin/' + props.rowData.id + '/users/'})"></btn>
                        </div>
                    </template>

                </custom-vue-table-card>

                <delete-modal ref="userDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                    <p>You are about to delete the User <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                        ?
                    </p>

                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                    </template>
                </delete-modal>

                <manage-users-mobile-view class="d-lg-none"></manage-users-mobile-view>

            </tab>

            <tab title="Bank e Auctions India.com Admins" v-if="currentUser.is_superuser"
                 class="c-scroll thin-scrollbar">

                <custom-vue-table-card class="d-sm-none-lg-block" :fields="fields" :url=venturaAdminList
                                       :per-page="10" :show-search-box="false"
                                       ref="table">

                    <template #buttons>
                        <btn class="ml-3" text="Add User" color="secondary"
                             @click="$router.push({path: '/ventura-admin/add-user/'})"
                             v-if="currentUser.is_superuser || currentUser.permission.can_add_ventura_user"></btn>
                    </template>

                    <template slot="attachment">
                        <router-link to="/" class="text-decoration-none line-bottom-primary">View</router-link>
                    </template>

                    <template slot="status" slot-scope="props">
                        <activate-suspend-buttons :active="props.rowData.is_active"
                                                  @activate="setIsActive(props.rowData, true)"
                                                  @suspend="setIsActive(props.rowData, false)">
                        </activate-suspend-buttons>
                    </template>

                    <template slot="actions" slot-scope="props">
                        <div class="btn-group">
                            <btn icon="fa fa-eye" design="" size="sm" class="border-gray-hover p-2 text-5"
                                 @click="setView(props.rowData)"></btn>

                            <btn icon="fa fa-pencil" design="" size="sm" class="border-gray-hover p-2 text-5"
                                 v-if="currentUser.is_superuser || currentUser.permission.can_edit_ventura_user"
                                 @click="setEdit(props.rowData)"></btn>

                            <btn icon="fa fa-trash" design="" size="sm" class="border-gray-hover p-2 text-5"
                                 @click="deleteItem(props.rowData)"
                                 v-if="currentUser.is_superuser || currentUser.permission.can_delete_ventura_user"></btn>

                        </div>
                    </template>

                </custom-vue-table-card>

                <modal title="User Update" ref="venturaAdminEditModal" :no-close-on-backdrop="true" width="60r"
                       header-color="primary">
                    <edit-ventura-admin :initial-data="editingItem" @success="editFormSuccess"/>
                </modal>

                <delete-modal ref="userDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                    <p>You are about to delete the User <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                        ?
                    </p>

                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                    </template>
                </delete-modal>

                <ventura-user-mobile-view ref="userListMobileView" class="d-lg-none" @view="setView"
                                          @edit="setEdit" @delete="deleteItem"/>

            </tab>

            <tab title="Bank e Auctions India.com Customers" class="c-scroll thin-scrollbar">

                <custom-vue-table-card class="d-sm-none-lg-block" :fields="customerFields" :url=venturaCustomersList
                                       :per-page="10" :show-search-box="false"
                                       ref="table">

                    <template slot="attachment">
                        <router-link to="/" class="text-decoration-none line-bottom-primary">View</router-link>
                    </template>

                    <template slot="is_active" slot-scope="props">
                        <activate-suspend-buttons :active="props.rowData.is_active"
                                                  @activate="customerSuspend(props.rowData, true)"
                                                  @suspend="customerSuspend(props.rowData, false)">
                        </activate-suspend-buttons>
                    </template>

                    <template slot="actions" slot-scope="props">
                        <div class="btn-group">
                            <btn icon="fa fa-eye" size="sm" design="" class="border-gray-hover p-2 text-5"
                                 @click="setCustomerView(props.rowData)"></btn>
                        </div>
                    </template>

                </custom-vue-table-card>

                <customer-mobile-view class="d-lg-none" @view="setCustomerView"></customer-mobile-view>

            </tab>

        </tabs>

    </div>
</template>

<script>
import ManageUsersMobileView from '../../views/users/ManageUsersMobileView';
import VenturaUserMobileView from './VenturaUserMobileView';
import ActivateSuspendButtons from '../../components/manage-users/ui/ActivateSuspendButtons';
import EditVenturaAdmin from '../../views/users/ventura-admin/EditVenturaAdmin';
import CustomerMobileView from './CustomerMobileView';
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'ManageUsersPage',

    components : {
        VenturaUserMobileView,
        ManageUsersMobileView,
        ActivateSuspendButtons,
        EditVenturaAdmin,
        CustomerMobileView
    },

    computed : { ...mapGetters(['currentUser']) },

    data () {
        return {

            selectedIndex : this.$route.params.selectedIndex,

            deletingItem : null,
            editingItem  : null,

            deleteUrl            : urls.userList.delete,
            bankAdminList        : urls.userList.bankAdmin.bankAdmin,
            venturaAdminList     : urls.userList.venturaAdmin.venturaAdmin,
            venturaCustomersList : urls.userList.venturaCustomers,

            fields : [
                {
                    name  : 'sl_no',
                    title : 'SL NO'
                },
                {
                    name  : 'id',
                    title : 'User ID'
                },
                {
                    name  : 'name',
                    title : 'Name'
                },
                {
                    name  : 'email',
                    title : 'Email'
                },
                {
                    name  : 'phone',
                    title : 'Phone'
                },
                {
                    name  : 'auction_created',
                    title : 'Auctions Created'
                },
                {
                    name  : '__slot:status',
                    title : 'status'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ],

            BankAdminFields : [
                {
                    name      : 'sl_no',
                    dataClass : 'aligned text-center',
                    title     : 'Sl. No'
                },
                {
                    name      : '__slot:bank_logo',
                    dataClass : 'aligned text-center',
                    title     : 'Bank/FI Logo'
                },
                {
                    name      : '__slot:bank_name',
                    dataClass : 'aligned text-center',
                    title     : 'Bank/FI Name'
                },
                {
                    name      : 'active_users',
                    dataClass : 'aligned text-center',
                    title     : 'Active Users'
                },
                {
                    name      : 'inactive_users',
                    dataClass : 'aligned text-center',
                    title     : 'Inactive Users'
                },
                {
                    name      : 'status',
                    dataClass : 'aligned text-center',
                    title     : 'Status'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ],

            customerFields : [
                {
                    name       : 'sl_no',
                    titleClass : 'center aligned text-center',
                    dataClass  : 'aligned text-center',
                    title      : 'SL NO'
                },
                {
                    name       : 'id',
                    titleClass : 'center aligned text-center',
                    dataClass  : 'aligned text-center',
                    title      : 'User ID'
                },
                {
                    name       : 'name',
                    titleClass : 'center aligned text-center',
                    dataClass  : 'aligned text-center',
                    title      : 'Name'
                },
                {
                    name       : 'email',
                    titleClass : 'center aligned text-center',
                    dataClass  : 'aligned text-center',
                    title      : 'Email'
                },
                {
                    name       : 'phone',
                    titleClass : 'center aligned text-center',
                    dataClass  : 'aligned text-center',
                    title      : 'Phone'
                },
                {
                    name       : '__slot:is_active',
                    titleClass : 'center aligned text-center',
                    dataClass  : 'aligned text-center',
                    title      : 'User Status'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ]

        };
    },

    methods : {
        setView (item) {
            this.$router.push('/user/' + item.id + '/details/1/');
        },

        setCustomerView (item) {
            let index = '';
            if (this.currentUser.is_superuser) {
                index = '2';
            } else {
                index = '1';
            }
            this.$router.push('/customer/' + item.id + '/details/' + index + '/');
        },

        editFormSuccess () {
            this.$refs.venturaAdminEditModal.close();
            this.$refs.userListMobileView.loadData();
            this.editingItem = null;
            this.$refs.table.refreshTable();
        },

        formSuccess () {
            this.$refs.UserModal.close();
            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.venturaAdminEditModal.show();
        },

        deleteItem (item) {
            this.deletingItem = { ...item };
            this.$refs.userDeleteModal.show();
        },

        deleteComplete (response) {
            this.$refs.userDeleteModal.close();
            this.$refs.userListMobileView.loadData();
            if (response.error === false) {
                this.$notify('Successfully deleted', 'success',
                    {
                        type      : 'success',
                        duration  : 3000,
                        permanent : false
                    });
                this.$refs.table.refreshTable();
            } else {
                this.deleteFailure(response);
            }
        },

        deleteFailure (json) {
            console.log(json);
            this.$notify(json.errors, 'Error',
                {
                    type      : 'warning',
                    duration  : 3000,
                    permanent : false
                }
            );
        },

        setIsActive (user, status) {
            const that = this;
            user.loading = true;

            const permission = this.checkPermission(status);

            if (!permission) {
                alert('Don\'t have permission for this Action..!');
                return;
            }

            const model = {
                user   : user.id,
                status : status
            };

            axios.form(urls.userList.statusUpdate, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    user.loading = false;
                    user.is_active = status;
                    that.$notify('Successfully updated the status', 'Success', { type : 'success' });
                } else {
                    user.loading = false;
                    user.is_active = !status;
                    that.$notify(json.message, 'Warning', { type : 'warning' });
                }
            }).catch(function (exception) {
                console.log('exception occurred : ', exception);
                user.is_active = !status;
            });
        },

        checkPermission (status) {
            if (status === true) {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_approve_ventura_user;
                }
            } else {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_reject_ventura_user;
                }
            }
        },

        customerSuspend (user, status) {
            const that = this;
            user.loading = true;

            const permission = this.checkPermission(status);

            if (!permission) {
                alert('Don\'t have permission for this Action..!');
                return;
            }

            const conform = confirm('Are you sure about it?');

            if (!conform) {
                return;
            }

            const model = {
                user   : user.id,
                status : status
            };

            axios.form(urls.userList.customerSuspend, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    user.loading = false;
                    user.is_active = status;
                    that.$notify('Successfully updated the status', 'Success', { type : 'success' });
                } else {
                    user.loading = false;
                    user.is_active = !status;
                    that.$notify(json.message, 'Warning', { type : 'warning' });
                }
            }).catch(function (exception) {
                console.log('exception occurred : ', exception);
                user.is_active = !status;
            });
        },

        tabChange (selectedTab) {
            this.$router.push({ name : 'ManageUsers', params : { selectedIndex : selectedTab } });
            this.selectedIndex = selectedTab;
        }
    }
};
</script>

<style scoped>

</style>
