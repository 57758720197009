<template>
    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>
            <div class="fl-eqh-r">
                <btn class="ml-3" text="Add User" color="secondary"
                     @click="$router.push({path: '/ventura-admin/add-user/'})"
                     v-if="currentUser.is_superuser || currentUser.permission.can_add_ventura_user"></btn>
            </div>
            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-2">
                <div class="gr-3-cols mt-4">

                    <div class="fl-gr-1 b-1">
                        <div class="fl-x fl-j-c fl-a-c">
                            <div class="pos-r py-2">
                                <div class=""></div>
                                <span class="font-inter-medium">Sl No</span>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1">
                        <div class="fl-x fl-j-s fl-j-c  fl-a-c">
                            <div class="pos-r py-2">
                                <div class=""></div>
                                <span class="font-inter-medium">User ID</span>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1">
                        <div class="fl-x fl-j-s fl-j-c  fl-a-c">
                            <div class="pos-r text-left py-2">
                                <div class=""></div>
                                <span class="font-inter-medium">Name</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="b-1 bs-3">
                    <div class="gr-3-cols font-inter-medium c-line-bottom">

                        <div class="b-1 py-2">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r">
                                    <div class=""></div>
                                    {{ i.sl_no }}
                                </div>
                            </div>
                        </div>

                        <div class="b-1 fl-j-c py-2">
                            <div class="pos-r fl-j-c pl-4">
                                <div class="capitalize-string"></div>
                                {{ i.id }}
                            </div>
                        </div>

                        <div class="b-1 py-2">
                            <div class="pos-r fl-j-c pl-1">
                                <div class=""></div>
                                {{ i.name }}
                            </div>
                        </div>

                    </div>

                    <div class="row b-1 fs--1 w m-0">

                        <span class="b-1 w-100 col-12">
                            <div class="p-1">
                               <span class="font-inter-medium">E-Mail</span> <span class="ml-2">{{ i.email }}</span>
                            </div>
                        </span>

                        <span class="b-1 w-100 col-6">
                            <div class="p-1">
                               <span class="font-inter-medium">Phone</span> <span class="ml-2">{{ i.phone }}</span>
                            </div>
                        </span>

                        <span class="b-1 w-100 col-6">
                            <div class="p-1">
                               <span class="font-inter-medium">Auctions Created</span> <span
                                class="ml-2">{{ i.auction_created }}</span>
                            </div>
                        </span>

                        <span class="b-1 w-100 col-6">
                            <div class="p-1">
                               <span class="font-inter-medium">Status</span> <span class="ml-2">
                                <activate-suspend-buttons :active="i.is_active"
                                                          @activate="setIsActive(i, true)"
                                                          @suspend="setIsActive(i, false)">
                                </activate-suspend-buttons>
                            </span>
                            </div>
                        </span>

                        <span class="b-1 col-12">
                    <div class="p-1 fl-x fl-j-s py-3">
                        <btn icon="fa fa-eye" design="" size="sm" class="border-gray-hover p-2 text-5"
                             @click="setView(i)"></btn>

                            <btn icon="fa fa-pencil" design="" size="sm" class="border-gray-hover p-2 text-5"
                                 v-if="currentUser.is_superuser || currentUser.permission.can_edit_ventura_user"
                                 @click="setEdit(i)"></btn>

                            <btn icon="fa fa-trash" design="" size="sm" class="border-gray-hover p-2 text-5"
                                 @click="deleteItem(i)"
                                 v-if="currentUser.is_superuser || currentUser.permission.can_delete_ventura_user"></btn>
                    </div>
                </span>
                    </div>

                </div>
            </div>

            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../data/urls';
import ActivateSuspendButtons from '../../components/manage-users/ui/ActivateSuspendButtons';

export default {
    name : 'VenturaUserMobileView',

    components : {
        ActivateSuspendButtons
    },

    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '10',
            loading  : true,
            error    : false,
            details  : null
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(urls.userList.venturaAdmin.venturaAdmin, {
                params : {
                    page     : that.page,
                    per_page : that.per_page
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.loading = false;
                that.error = false;
                that.setPagination();
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        },

        setView (item) {
            this.$emit('view', item);
        },

        setEdit (item) {
            this.$emit('edit', item);
        },

        deleteItem (item) {
            this.$emit('delete', item);
        },

        setIsActive (user, status) {
            const that = this;
            user.loading = true;

            const permission = this.checkPermission(status);

            if (!permission) {
                alert('Don\'t have permission for this Action..!');
                return;
            }

            const conform = confirm('Are you sure about it?');

            if (!conform) {
                return;
            }

            const model = {
                user   : user.id,
                status : status
            };

            axios.form(urls.userList.statusUpdate, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    user.loading = false;
                    user.is_active = status;
                    that.$notify('Successfully updated the status', 'Success', { type : 'success' });
                } else {
                    user.loading = false;
                    user.is_active = !status;
                    that.$notify(json.message, 'Warning', { type : 'warning' });
                }
            }).catch(function (exception) {
                console.log('exception occurred : ', exception);
                user.is_active = !status;
            });
        },

        checkPermission (status) {
            if (status === true) {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_approve_ventura_user;
                }
            } else {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_reject_ventura_user;
                }
            }
        }

    }
};
</script>

<style scoped>

</style>
