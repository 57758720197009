<template>
    <b-form class="row" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :initial-data="initialData">

        <div class="col-6">
            <validated-input disabled label="Username" placeholder="Username" class="c-form-input"
                             v-model="model.username" :rules="{required:true}" @blur="checkUsername(model.username)"/>
        </div>

        <div class="col-6">
            <validated-input label="Employee ID*" placeholder="Example: ENP576281" class="c-form-input"
                             v-model="model.employee_number" name="Employee ID"
                             :rules="{required:true}"
            ></validated-input>
        </div>

        <div class="col-6">
            <validated-input label="First Name*" placeholder="First Name" class="c-form-input"
                             v-model="model.first_name" name="First Name"
                             :rules="{required:true}"
            ></validated-input>
        </div>

        <div class="col-6">
            <validated-input label="Last Name*" placeholder="Last Name" class="c-form-input"
                             v-model="model.last_name" name="Last Name"
                             :rules="{required:true}"
            ></validated-input>
        </div>

        <div class="col-6">
            <validated-input label="Mobile Number*" placeholder="Mobile number" class="c-form-input"
                             v-model="model.mobile_number" type="number" name="Mobile number"
                             :rules="{required:true, min:10, max:10}" @blur="checkMobile(model.mobile_number)"/>
        </div>

        <div class="col-6">
            <validated-input label="E-Mail ID*" placeholder="E-Mail ID" type="email"
                             class="c-form-input" name="E-Mail ID"
                             v-model="model.email" @blur="checkEmailExists(model.email)"
                             :rules="{required:rules.email}"/>
        </div>

        <div class="col-12">
            <validated-input label="Role*" placeholder="Example: Database Manager" class="c-form-input"
                             v-model="model.employee_role"
                             :rules="{required:true}"
            ></validated-input>
        </div>

        <div class="col-12">
            <div class="fl-x fl-j-c">
                <btn text="Update" loading-text="Updating..." :loading="loading" class="px-5 ml-3" design="basic-a"
                     color="secondary" :disabled="loading"></btn>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'EditVenturaAdmin',

    props : { initialData : { type : Object } },

    data () {
        return {
            loading : false,
            rules   : {
                email : {
                    required : true
                }
            },
            addUrl : urls.userList.venturaAdmin.addEdit,

            userId : ''
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Updated the user..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        checkUsername (username) {
            const that = this;
            const data = {
                username : username
            };

            axios.form(urls.userList.usernameCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    that.$refs.formValidator.setErrors({ Username : json.message });
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        },

        checkEmailExists (email) {
            const that = this;
            const data = {
                email : email
            };
            axios.form(urls.userList.emailCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    if (!that.invalidEmail) {
                        that.$refs.formValidator.setErrors({ email : json.message });
                    }
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        },

        checkMobile (mobile) {
            const that = this;
            const data = {
                mobile : mobile
            };

            axios.form(urls.userList.mobileCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    that.$refs.formValidator.setErrors({ 'Mobile number' : json.message });
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        }
    }
};
</script>

<style scoped>

</style>
