<template>

    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-4">

                <div class="gr-3-cols">

                    <div class="fl-gr-1 b-1">
                        <!--                        <div class="fl-x fl-j-c fl-a-c">-->
                        <div class="text-center">
                            <div class="pos-r py-2 fl-j-c">
                                <!--                                <div class="line-right-1"></div>-->
                                <span class="font-inter-medium">Sl No</span>
                            </div>
                        </div>
                    </div>
                    <div class="fl-gr-1 b-1">
                        <!--                        <div class="fl-x fl-j-s fl-a-c">-->
                        <div class="text-center">
                            <div class="pos-r py-2 fl-j-c">
                                <!--                                <div class="line-right-1"></div>-->
                                <span class="font-inter-medium">Bank/FI Logo</span>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1">
                        <!--                        <div class="fl-x fl-j-s fl-a-c">-->
                        <div class="text-center">
                            <div class="pos-r py-2 fl-j-c">
                                <!--                                <div class="line-right-1"></div>-->
                                <span class="font-inter-medium">Bank/FI Name</span>
                            </div>
                        </div>
                    </div>

<!--                    <div class="fl-gr-1 b-1">-->
<!--                        &lt;!&ndash;                        <div class="fl-x fl-j-s fl-a-c">&ndash;&gt;-->
<!--                        <div class="text-center">-->
<!--                            <div class="pos-r py-2 fl-j-c ">-->
<!--                                &lt;!&ndash;                                <div class="line-right-1"></div>&ndash;&gt;-->
<!--                                <span class="font-inter-medium">Active Users</span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                </div>
                <div class="b-1 bs-3">
                    <div class="gr-3-cols font-inter-medium c-line-bottom">

                        <div class="br-1 py-2">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r fl-j-c">
                                    <div class=""></div>
                                    {{ i.sl_no }}
                                </div>
                            </div>
                        </div>
                        <div class="b-1 py-2 fl-x-cc">
                            <div class="logo-container">
                                <img v-if="i.bank_logo" class="img-fluid" :src="i.bank_logo"
                                     alt="#BankLogo">
                            </div>
                        </div>

                        <div class="br-1 pl-3 py-2">
                            <div class="pos-r fl-j-c">
                                <div class="br-1 capitalize-string"></div>
                                {{ i.bank_name }}
                            </div>
                        </div>

<!--                        <div class="pl-3 py-2">-->
<!--                            <div class="pos-r fl-j-c">-->
<!--                                <div class="line-right-1"></div>-->
<!--                                {{ i.active_users }}-->
<!--                            </div>-->
<!--                        </div>-->

                    </div>

                    <div class="row ">
                        <span class="b-1 w-100 col-6">
                            <div class="pt-2 pb-2 pl-2">
                               <span class="font-inter-medium ">Active Users</span> <span
                                class="ml-2 fs-1">{{ i.active_users }}</span>
                            </div>
                        </span>
                        <span class="b-1 w-100 col-6">
                            <div class="pt-2 pb-2 pl-2">
                               <span class="font-inter-medium ">Inactive Users</span> <span
                                class="ml-2 fs-1">{{ i.inactive_users }}</span>
                            </div>
                        </span>

                        <span class="b-1 w-100 col-6">
                            <div class="p-2">
                               <span class="font-inter-medium ">Status</span> <span class="ml-2">{{ i.status }}</span>
                            </div>
                        </span>

                        <span class="col-12">
                    <div class="p-1 text-center py-3 b-1">
                        <btn class="ml-3" text="View Users" color="secondary"
                             @click="$router.push({path: '/bank-admin/' + i.id + '/users/'})"></btn>
                    </div>
                </span>
                    </div>

                </div>
            </div>

            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name : 'ManageUsersMobileView',

    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '10',
            loading  : true,
            error    : false,
            details  : null
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(urls.userList.bankAdmin.bankAdmin, {
                params : {
                    page     : that.page,
                    per_page : that.per_page
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.loading = false;
                that.error = false;
                that.setPagination();
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        },

        setView (item) {
            this.$emit('view', item);
        },

        setEdit (item) {
            this.$emit('edit', item);
        },

        deleteItem (item) {
            this.$emit('delete', item);
        },

        toggleUserActiveStatus (i) {
            this.items[i].active = !this.items[i].active;
        }

    }
};
</script>

<style scoped lang="scss">
.gr-4-cols {
    display: grid;
    grid-template-columns:repeat(4, 1fr);
}

.gr-3-cols {
    display: grid;
    grid-template-columns:repeat(3, 1fr);
}

.c-line-bottom {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: rgba(128, 128, 128, 0.3);
    }
}

.icon-danger {
    i {
        color: var(--color-danger) !important;
    }
}

.icon-primary {
    i {
        color: var(--color-primary) !important;
    }
}
</style>
